export const norwegian = {
    Categories: 'Kategorier',
    Products: 'Produkter',
    Search: 'Søk',
    PCS: 'STK',
    Decrement: 'Reduser',
    Increment: 'Øk',
    Cart: 'Handlekurv',
    AddToCart: 'Legg til handlekurv',
    ChooseQuantity: 'Velg antall',
    Close: 'Lukk',
    EmptyCart: 'Tøm handlekurv',
    EmptyCartMessage: 'Handlekurven er tom. Legg til noe for å eksportere.',
    Export: 'Eksporter',
    Remove: 'Fjern',
    Copy: 'Kopier',
    Copied: 'Kopiert',
    GenerateFile: 'Generer {{file}}-fil',
    GenerateFileError: 'Kunne ikke generere {{file}}-fil',
    DownloadFile: 'Last ned {{file}}-fil',
    NoProductsFound: 'Ingen produkter funnet',
    Error: 'Feil',
    Name: 'Navn',
    Category: 'Kategori',
    CartItemsCount: '{{count}} ting',
    CartItemsCount_plural: '{{count}} ting',
    PaginatorSummary: 'Side {{page}} av {{pageCount}}, viser rader {{start}}-{{end}} av totalt {{rowCount}}',
};
