import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import WagoExport from './Export/WagoExport';
import SymbrioExport from './Export/SymbrioExport';
import SolarExport from './Export/SolarExport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import ExcelExport from './Export/ExcelExport';

function CartExportModal({ showModal, handleHideExportModal, cartItems }) {
    const { t } = useTranslation();

    if (!showModal) {
        return <></>;
    }

    return (
        <Modal show={showModal}>
            <Modal.Header onHide={handleHideExportModal} closeButton>
                <Modal.Title>
                    <h5 className="modal-title">{t('Export')}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SymbrioExport cartItems={cartItems} />
                <hr />
                <SolarExport cartItems={cartItems} />
                <hr />
                <WagoExport cartItems={cartItems} />
                <hr />
                <ExcelExport cartItems={cartItems} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={handleHideExportModal}
                >
                    <FontAwesomeIcon icon={faTimesCircle} /> {t('Close')}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

CartExportModal.propTypes = {
    showModal: PropTypes.bool,
    handleHideExportModal: PropTypes.func.isRequired,
    cartItems: PropTypes.array.isRequired,
};

export default CartExportModal;
