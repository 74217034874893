export const english = {
    Categories: 'Categories',
    Products: 'Products',
    Search: 'Search',
    PCS: 'PCS',
    Decrement: 'Decrement',
    Increment: 'Increment',
    Cart: 'Cart',
    AddToCart: 'Add to cart',
    ChooseQuantity: 'Choose Quantity',
    Close: 'Close',
    EmptyCart: 'Empty cart',
    EmptyCartMessage: 'Cart is empty. Add something to export.',
    Export: 'Export',
    Remove: 'Remove',
    Copy: 'Copy',
    Copied: 'Copied',
    GenerateFile: 'Generate {{file}} file',
    GenerateFileError: 'Could not generate {{file}} file',
    DownloadFile: 'Download {{file}} file',
    NoProductsFound: 'No products found',
    Error: 'Error',
    Name: 'Name',
    Category: 'Category',
    CartItemsCount: '{{count}} item',
    CartItemsCount_plural: '{{count}} items',
    PaginatorSummary: 'Page {{page}} of {{pageCount}}, showing rows {{start}}-{{end}} of total {{rowCount}}',
};
