import PropTypes from 'prop-types';
import { useState } from 'react';
import { exports } from '../../../services/Api/ProductsApi';
import { useTranslation } from 'react-i18next';

function WagoExport({ cartItems }) {
    const { t } = useTranslation();
    const [disableGenerateExcel, setDisableGenerateExcel] = useState(false);
    const [showExcelDownload, setShowExcelDownlad] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [error, setError] = useState(false);
    const handleExportButton = () => {
        exports(cartItems, 'xlsx').then((data) => {
            if (!data['success']) {
                setDisableGenerateExcel(false);
                setShowExcelDownlad(false);

                return setError(true);
            }
            setError(false);
            setShowExcelDownlad(true);
            setDownloadLink(data['downloadLink']);
            setDisableGenerateExcel(true);
        });
    };

    return (
        <>
            <h4>Excel</h4>
            <div className="d-flex justify-content-start mt-4">
                <button
                    id="exportExcelButton"
                    className="btn btn-primary mr-2"
                    type="button"
                    onClick={handleExportButton}
                    disabled={disableGenerateExcel}
                >
                    {t('GenerateFile', { file: 'Excel' })}
                </button>
                {showExcelDownload && (
                    <a
                        href={downloadLink}
                        className="btn btn-success"
                        aria-label="Export Excel"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('DownloadFile', { file: 'Excel' })}
                    </a>
                )}
            </div>
            {error && <h5 className="text-danger mt-2">{t('GenerateFileError', { file: 'Excel' })}</h5>}
        </>
    );
}

WagoExport.propTypes = {
    cartItems: PropTypes.array.isRequired,
};

export default WagoExport;
