import { useEffect, useState } from 'react';
import { view, ProductType } from '../services/Api/ProductsApi';
import { Link, useParams } from 'react-router-dom';
import Placeholder from '../components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faSignature, faSitemap } from '@fortawesome/pro-light-svg-icons';
import Breadcrumbs from '../components/Breadcrumbs';
import useCopyable from '../hooks/useCopyable';
import { useTranslation } from 'react-i18next';

function ProductPage() {
    const { t } = useTranslation();
    const { id } = useParams();

    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [product, setProduct] = useState(ProductType);

    const { copyIcon: copyArticleIcon } = useCopyable(product.articleNumber);
    const { copyIcon: copyElIcon } = useCopyable(product.elNumber);

    useEffect(() => {
        setIsLoaded(false);
        view(id).then((result) => {
            setError(result.error || null);
            setProduct(result.product);
            setIsLoaded(true);
        });
    }, [id]);

    if (error) {
        return (
            <div>
                {t('Error')}: {error.message}
            </div>
        );
    }
    if (!isLoaded) {
        return <Placeholder />;
    }

    const prettyElNumber = (elNumber) =>
        elNumber.substring(0, 2) + ' ' + elNumber.substring(2, 5) + ' ' + elNumber.substring(5, 7);

    return (
        <>
            <Breadcrumbs
                title={t('Categories')}
                baseLink="/categories"
                crumbs={[{ id: product.category.id, name: product.category.name, slug: product.category.slug }]}
            />
            <Breadcrumbs
                title={t('Products')}
                baseLink="/products"
                crumbs={[{ id: product.id, name: product.name, slug: product.slug }]}
            />
            <div className="row">
                <div className="col-12 tool-thumb text-center">
                    <div className="text-center m-2">
                        <img
                            src={process.env.REACT_APP_ASSETS_URL + 'products/' + product.image}
                            alt=""
                            style={{ height: '75px' }}
                            className="img-thumbnail"
                        />
                    </div>
                </div>
                <div className="col-sm-12 ml-sm-auto col-md-12 pt-3">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>
                                        <FontAwesomeIcon icon={faSignature} /> {t('Name')}
                                    </td>
                                    <td>{product.name}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <FontAwesomeIcon icon={faSitemap} /> {t('Category')}
                                    </td>
                                    <td>
                                        {product.hasOwnProperty('category') ? (
                                            <Link to={'/categories/' + product.category.id + '/' + product.slug}>
                                                {product.category.name}
                                            </Link>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FontAwesomeIcon icon={faLink} /> Wago
                                    </td>
                                    <td>
                                        {copyArticleIcon}{' '}
                                        <a href={product.wagoLink} target="_blank" rel="noreferrer">
                                            {product.articleNumber}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FontAwesomeIcon icon={faLink} /> EFO
                                    </td>
                                    <td>
                                        {copyElIcon}{' '}
                                        <a href={product.efoLink} target="_blank" rel="noreferrer">
                                            {prettyElNumber(product.elNumber)}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductPage;
