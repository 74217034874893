import { useEffect, useState } from 'react';
import Categories from '../components/Categories';
import Placeholder from '../components/Placeholder';
import { CategoryType, index } from '../services/Api/CategoriesApi';
import { useTranslation } from 'react-i18next';

function CategoriesPage() {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState([CategoryType]);

    useEffect(() => {
        setIsLoaded(false);
        index().then((result) => {
            setError(result.error || null);
            setCategories(result.categories);
            setIsLoaded(true);
        });
    }, []);

    if (error) {
        return (
            <div>
                {t('Error')}: {error.message}
            </div>
        );
    }
    if (!isLoaded) {
        return <Placeholder />;
    }

    return <Categories categories={categories} />;
}

export default CategoriesPage;
