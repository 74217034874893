import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faSitemap } from '@fortawesome/pro-duotone-svg-icons';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Navigation({ searchForm }) {
    const { t } = useTranslation();
    let location = useLocation();

    const navigationLinks = [
        {
            key: 0,
            name: t('Categories'),
            icon: <FontAwesomeIcon icon={faSitemap} />,
            link: '/categories',
            main: true,
        },
        {
            key: 1,
            name: t('Products'),
            icon: <FontAwesomeIcon icon={faLayerGroup} />,
            link: '/products',
        },
    ];

    const isMainPageActive = (link) => location.pathname === '/' && link.hasOwnProperty('main');

    const navigationLinksContent = navigationLinks.map((link) => {
        let className = 'nav-link';
        if (isMainPageActive(link) || location.pathname.startsWith(link.link)) {
            className += ' btn btn-primary text-light btn-sm text-left';
        }

        return (
            <Nav.Link
                key={link.key}
                as={Link}
                to={link.link}
                className={className}
                style={{ display: 'inline-block', padding: '.5em' }}
            >
                <span className="text-darkblue">{link.icon}</span> {link.name}
            </Nav.Link>
        );
    });

    return (
        <Navbar bg="light" expand="md">
            <Navbar.Toggle aria-controls="navbar" className="mb-2" />
            <Navbar.Collapse id="navbar">
                <Nav className="mr-auto">{navigationLinksContent}</Nav>
                {searchForm}
            </Navbar.Collapse>
        </Navbar>
    );
}

Navigation.propTypes = {
    searchForm: PropTypes.element.isRequired,
};

export default Navigation;
