import { Router, Route, Switch } from 'react-router-dom';
import Navigation from './Navigation';
import CategoriesPage from '../pages/CategoriesPage';
import CategoryPage from '../pages/CategoryPage';
import ProductsPage from '../pages/ProductsPage';
import ProductPage from '../pages/ProductPage';
import SearchPage from '../pages/SearchPage';
import useSearch from '../hooks/useSearch';
import useCart from '../hooks/useCart';
import history from '../history.js';

function Content() {
    const { searchForm } = useSearch(history);
    const { cartButton, cartModal, cartActions } = useCart();

    return (
        <Router history={history}>
            <Navigation searchForm={searchForm} />
            {cartButton} {cartModal}
            <Switch>
                <Route path="/categories/:id">
                    <CategoryPage cartActions={cartActions} />
                </Route>
                <Route path="/categories">
                    <CategoriesPage />
                </Route>
                <Route path="/products/:id">
                    <ProductPage />
                </Route>
                <Route path="/products">
                    <ProductsPage cartActions={cartActions} />
                </Route>
                <Route path="/search/:searchTerm">
                    <SearchPage cartActions={cartActions} searchForm={searchForm} />
                </Route>
                <Route path="/">
                    <CategoriesPage />
                </Route>
            </Switch>
        </Router>
    );
}

export default Content;
