function ErrorRender({ message, url }) {
    return (
        <div className="text-white text-center d-flex justify-content-center align-items-center">
            <div className="mt-3 p-3 w-75 bg-danger rounded">
                <div className="mb-3">
                    <img src={window.location.origin + '/img/exclamation.png'} alt="" style={{ width: '75px' }} />
                </div>
                <div>
                    <p>When trying to access `{url}`, something went wrong.</p>
                    <p>Message: {message}</p>
                    <p>Our apologies!</p>
                </div>
            </div>
        </div>
    );
}

export default ErrorRender;
