import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useCopyable from '../hooks/useCopyable';

function Product({ product, cartActions }) {
    const prettyElNumber = (elNumber) =>
        elNumber.substring(0, 2) + ' ' + elNumber.substring(2, 5) + ' ' + elNumber.substring(5, 7);

    const { copyIcon: copyArticleIcon } = useCopyable(product.articleNumber);
    const { copyIcon: copyElIcon } = useCopyable(product.elNumber);

    const productLinks = [
        <div key={1}>
            {copyArticleIcon}{' '}
            <a href={product.wagoLink} target="_blank" rel="noreferrer">
                {product.articleNumber}
            </a>
        </div>,
    ];
    if (product.elNumber.length > 0) {
        productLinks.push(
            <div key={2}>
                {copyElIcon}{' '}
                <a href={product.efoLink} target="_blank" rel="noreferrer">
                    {prettyElNumber(product.elNumber)}
                </a>
            </div>,
        );
    }

    return (
        <div className="list-group-item d-flex justify-content-start align-items-center">
            <div aria-label="Product picture">
                <img
                    src={process.env.REACT_APP_ASSETS_URL + 'products/' + product.image}
                    alt=""
                    className="tool-image align-self-center mr-3"
                />
            </div>
            <div aria-label="Product" className="ml-3 w-100">
                <h5 className="mt-0 random-style">
                    <Link to={'/products/' + product.id + '/' + product.slug}>{product.name}</Link>
                </h5>
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div aria-label="Product links">{productLinks}</div>
                    <div aria-label="Product cart actions" className="ml-md-auto mt-2 mt-md-0">
                        {cartActions(product)}
                    </div>
                </div>
            </div>
        </div>
    );
}

Product.propTypes = {
    product: PropTypes.object.isRequired,
    cartActions: PropTypes.elementType.isRequired,
};

export default Product;
