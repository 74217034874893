import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Breadcrumbs({ title, baseLink, crumbs }) {
    const breadcrumbs = crumbs.map((crumb) => {
        return (
            <li className="breadcrumb-item" key={crumb.name}>
                <Link to={baseLink + '/' + crumb.id + '/' + crumb.slug}>{crumb.name}</Link>
            </li>
        );
    });

    return (
        <ul className="breadcrumb">
            <li className="breadcrumb-item" key="0">
                <Link to={baseLink}>{title}</Link>
            </li>
            {breadcrumbs}
        </ul>
    );
}

Breadcrumbs.propTypes = {
    title: PropTypes.string.isRequired,
    baseLink: PropTypes.string.isRequired,
    crumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
