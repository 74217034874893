import PropTypes from 'prop-types';
import useCopyable from '../../../hooks/useCopyable';

function SolarExport({ cartItems }) {
    const cartItemsText = cartItems.map((item) => item.product.elNumber + ';' + item.qty).join('\n');

    const { copyButton } = useCopyable(cartItemsText);
    return (
        <>
            <h4>Solar</h4>
            <div className="input-group mb-3">
                <textarea
                    className="form-control"
                    aria-label="Solar Export"
                    rows={cartItems.length <= 5 ? 5 : cartItems.length + 1}
                    defaultValue={cartItemsText}
                    onFocus={(event) => event.target.select()}
                />
            </div>
            {copyButton}
        </>
    );
}

SolarExport.propTypes = {
    cartItems: PropTypes.array.isRequired,
};

export default SolarExport;
