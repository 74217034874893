import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PaginatorType } from '../services/Api/PaginatorType';
import { useTranslation } from 'react-i18next';

function usePaginator(incomingParams) {
    const { t } = useTranslation();
    const [params, setParams] = useState(PaginatorType);

    useEffect(() => {
        setParams(incomingParams);
    }, [incomingParams]);

    // If there is only the one page, do not render any pagination links
    if (params.pageCount <= 1) {
        return <></>;
    }

    /**
     * Create a page link with the given page.
     * If no `text` parameter is given, the link text will be the `page` parameter
     *
     * @param {number} page
     * @param {string=} text
     * @returns {JSX.Element}
     */
    const pageLink = (page, text) => (
        <Link to={'?page=' + page} className="page-link">
            {text || page}
        </Link>
    );

    /**
     * If the given page is the requested page, return the active class name
     *
     * @param page
     * @returns {string}
     */
    const isCurrentClass = (page) => (page === params.requestedPage ? ' active' : '');

    /**
     * Find which page is the first page to be listed and which is the last.
     *
     * @returns {[number, number]}
     */
    const getNumbersStartAndEnd = () => {
        const half = Math.floor(modulus / 2);
        let end = Math.max(1 + modulus, params.requestedPage + half);
        let start = Math.min(params.pageCount - modulus, params.requestedPage - half - (modulus % 2));

        end = Math.min(params.pageCount, end);
        start = Math.max(1, start);

        return [start, end];
    };

    // How many page links to show, excluding the current page link
    const modulus = 4;
    let [start, end] = getNumbersStartAndEnd();

    // Add current page link and surround it with half of the modulus on either side of current page
    let paginatorLinks = [];
    for (let i = start; i <= end; i++) {
        paginatorLinks.push(
            <li className={'page-item' + isCurrentClass(i)} key={i}>
                {pageLink(i)}
            </li>,
        );
    }
    // Add first page and previous page links
    if (params.prevPage) {
        paginatorLinks.unshift(
            <li className="page-item" key={-2}>
                {pageLink(params.requestedPage - 1, '<')}
            </li>,
        );
        paginatorLinks.unshift(
            <li className="page-item" key={-3}>
                {pageLink(1, '<<')}
            </li>,
        );
    }
    // Add next page and last page links
    if (params.nextPage) {
        paginatorLinks.push(
            <li className="page-item" key={-1}>
                {pageLink(params.requestedPage + 1, '>')}
            </li>,
        );
        paginatorLinks.push(
            <li className="page-item" key={0}>
                {pageLink(params.pageCount, '>>')}
            </li>,
        );
    }

    // Create the pagination content, using the paginatorLinks array filled with page links
    const paginatorContent = (
        <nav aria-label="Page navigation" className="mt-3 px-2">
            <ul className="pagination d-flex justify-content-center">{paginatorLinks}</ul>
            <p className="text-center">
                {t('PaginatorSummary', {
                    page: params.requestedPage,
                    pageCount: params.pageCount,
                    start: params.start,
                    end: params.end,
                    rowCount: params.count,
                })}
            </p>
        </nav>
    );

    return { paginatorContent };
}

export default usePaginator;
