import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CategoryType, view } from '../services/Api/CategoriesApi';
import Categories from '../components/Categories';
import Product from '../components/Product';
import Placeholder from '../components/Placeholder';
import ErrorRender from '../components/ErrorRender';

function CategoryPage({ cartActions }) {
    const { id } = useParams();

    const [error, setError] = useState(false);
    const [errorRender, setErrorRender] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [crumbs, setCrumbs] = useState([
        {
            id: -1,
            name: '',
        },
    ]);
    const [category, setCategory] = useState(CategoryType);

    useEffect(() => {
        setIsLoaded(false);
        view(id).then((result) => {
            if (result.hasOwnProperty('code') && result.hasOwnProperty('message')) {
                setError(true);

                return setErrorRender(<ErrorRender code={result.code} message={result.message} url={result.url} />);
            }
            setCategory(result.category);
            setCrumbs(result.crumbs);
            setIsLoaded(true);
        });
    }, [id]);

    if (error) {
        return errorRender;
    }
    if (!isLoaded) {
        return <Placeholder />;
    }

    const productsContent = category.products.map((product) => (
        <Product key={product.id} product={product} cartActions={cartActions} />
    ));

    return (
        <>
            <Categories categories={category.child_categories} crumbs={crumbs} />
            <div className="list-group list-group-flush">{productsContent}</div>
        </>
    );
}

export default CategoryPage;
