export const PaginatorType = {
    count: 1,
    current: 1,
    perPage: 20,
    page: 1,
    requestedPage: 1,
    pageCount: 1,
    start: 1,
    end: 1,
    prevPage: false,
    nextPage: false,
    sort: null,
    direction: null,
    sortDefault: false,
    directionFault: false,
    completeSort: [],
    limit: null,
    scope: null,
    finder: '',
}
