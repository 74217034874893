import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import { useTranslation } from 'react-i18next';

function Categories({ categories, crumbs }) {
    const { t } = useTranslation();
    const crumbsContent = crumbs ? <Breadcrumbs crumbs={crumbs} title={t('Categories')} baseLink="/categories" /> : '';

    const categoriesContent = categories.map((category) => (
        <Link
            to={'/categories/' + category.id + '/' + category.slug}
            className="list-group-item list-group-item-action"
            key={category.id}
        >
            <div className="media align-items-center">
                <img
                    src={process.env.REACT_APP_ASSETS_URL + 'categories/' + category.image}
                    alt=""
                    className="tool-image align-self-center mr-3"
                />
                <div className="media-body">
                    <h5 className="mt-0">{category.name}</h5>
                </div>
            </div>
        </Link>
    ));

    return (
        <>
            {crumbsContent}
            <div className="list-group list-group-flush">{categoriesContent}</div>
        </>
    );
}

Categories.propTypes = {
    categories: PropTypes.array.isRequired,
    crumbs: PropTypes.array,
};

export default Categories;
