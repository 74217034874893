import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faCopy as faCopySolid } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function useCopyable(textToCopy) {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const copyIcon = isCopied ? (
        <FontAwesomeIcon icon={faCopySolid} style={{ color: 'green' }} />
    ) : (
        <FontAwesomeIcon className="cursor-copy" title={t('Copy')} icon={faCopy} onClick={handleCopy} />
    );

    const copyButton = isCopied ? (
        <button className="btn btn-success">
            <FontAwesomeIcon icon={faCopySolid} /> {t('Copied')}
        </button>
    ) : (
        <button className="btn btn-outline-success" onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} /> {t('Copy')}
        </button>
    );

    function handleCopy() {
        setIsCopied(true);
        const textBox = document.createElement('textarea');
        textBox.style.opacity = '0';
        textBox.readOnly = true;
        textBox.value = textToCopy;
        document.body.appendChild(textBox);
        textBox.select();
        document.execCommand('copy');
        textBox.remove();
        window.setTimeout(() => setIsCopied(false), 1000);
    }

    return { copyIcon, copyButton };
}

useCopyable.propTypes = {
    textToCopy: PropTypes.string.isRequired,
};

export default useCopyable;
