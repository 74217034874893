import { useState } from 'react';
import CartModal from '../components/Cart/CartModal';
import CartActions from '../components/Cart/CartActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import useCartStorage from './useCartStorage';
import { useTranslation } from 'react-i18next';

function useCart() {
    const {
        getStorageItems,
        addItemToStorage,
        updateItemQtyStorage,
        removeItemFromStorage,
        clearStorage,
    } = useCartStorage();
    const { t } = useTranslation();
    const [cartItems, setCartItems] = useState(getStorageItems());
    const cartItemsCount = cartItems.length;
    const [showModal, setShowModal] = useState(false);

    const handleHideCartModal = () => {
        setShowModal(false);
    };

    const handleEmptyCart = () => {
        setCartItems([]);
        clearStorage();
    };

    const handleRemoveItem = (itemId) => {
        setCartItems((prevState) => prevState.filter((item) => item.product.id !== itemId));
        removeItemFromStorage(itemId);
    };

    const handleUpdateItem = (itemId, newQty) => {
        newQty = parseInt(newQty);
        setCartItems((prevState) => {
            return prevState.map((item) => {
                if (item.product.id === itemId) {
                    item.qty = newQty;
                }
                updateItemQtyStorage(item.product.id, item.qty);

                return item;
            });
        });
    };

    const cartModal = (
        <CartModal
            showModal={showModal}
            handleHideModal={handleHideCartModal}
            handleEmptyCart={handleEmptyCart}
            handleRemoveItem={handleRemoveItem}
            handleUpdateItem={handleUpdateItem}
            cartItems={cartItems}
        />
    );

    const cartButton = (
        <button className="btn btn-success mb-2 ml-3" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faShoppingCart} /> {t('CartItemsCount', { count: cartItemsCount })}
        </button>
    );

    const addItemToCart = (product, qty) => {
        setCartItems((prevState) => {
            const itemExists = prevState.find((item) => item.product.id === product.id);
            // If item exists, append qty
            if (itemExists !== undefined) {
                return prevState.map((item) => {
                    if (item.product.id === product.id) {
                        item.qty += qty;
                    }
                    updateItemQtyStorage(item.product.id, item.qty);

                    return item;
                });
            }
            // Add new item
            addItemToStorage({ product, qty });
            return [
                ...prevState,
                {
                    product,
                    qty,
                },
            ];
        });
    };

    const cartActions = (product, qty) => (
        <CartActions key={product.id} product={product} externalQty={qty} addItemToCart={addItemToCart} />
    );

    return { cartButton, cartModal, cartActions };
}

export default useCart;
