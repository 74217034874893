import {CategoryType} from "./CategoriesApi";

const index = (page) => {
    let url = process.env.REACT_APP_API_URL + 'products.json'
    if (page !== undefined) {
        url += '?page=' + page;
    }

    return fetch(url).then(res => res.json())
}

const search = (term, page) => {
    let url = process.env.REACT_APP_API_URL + 'products/search/' + term + '.json'
    if (page !== undefined) {
        url += '?page=' + page;
    }

    return fetch(url).then(res => res.json())
}

const view = (id) => {
    return fetch(process.env.REACT_APP_API_URL + 'products/' + id + '.json').then(res => res.json())
}

const exports = (products, type) => {
    const requestInit = {
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(products),
        method: 'POST',
    }

    return fetch(process.env.REACT_APP_API_URL + 'exports/' + type + '.json', requestInit).then(response => response.json())
}

const ProductType = {
    id: -1,
    category_id: -1,
    name: '',
    articleNumber: '',
    elNumber: '',
    image: '',
    slug: '',
    wagoLink: '',
    efoLink: '',
    category: CategoryType,
}

export {index, search, view, exports, ProductType}
