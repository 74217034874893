import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import CartTable from './CartTable';
import CartExportModal from './CartExportModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';

function CartModal({ showModal, handleHideModal, handleEmptyCart, handleRemoveItem, handleUpdateItem, cartItems }) {
    const { t } = useTranslation();
    const [showExportModal, setShowExportModal] = useState(false);

    const handleShowExportModal = () => {
        setShowExportModal(true);
    };

    const handleHideExportModal = () => {
        setShowExportModal(false);
    };

    const cartModal = (
        <Modal show={showModal} onHide={handleHideModal} size={'lg'}>
            <Modal.Header closeButton>
                <h5 className="modal-title" id="cartModalTitle">
                    <FontAwesomeIcon icon={faShoppingCart} className="text-success" /> {t('Cart')}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <div className="table table-responsive-sm">
                    <CartTable
                        cartItems={cartItems}
                        handleRemoveItem={handleRemoveItem}
                        handleUpdateItem={handleUpdateItem}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="flex-wrap justify-content-center justify-content-md-end pt-1">
                <button
                    aria-label="Close Cart"
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={handleHideModal}
                >
                    {t('Close')}
                </button>
                <button
                    aria-label={t('EmptyCart')}
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={handleEmptyCart}
                    disabled={cartItems.length === 0}
                >
                    {t('EmptyCart')}
                </button>
                <button
                    aria-label="Open export dialog"
                    type="button"
                    className="btn btn-success mt-2"
                    onClick={handleShowExportModal}
                    disabled={cartItems.length === 0}
                >
                    {t('Export')}
                </button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <>
            {showModal && !showExportModal && cartModal}
            <CartExportModal
                showModal={showExportModal}
                handleHideExportModal={handleHideExportModal}
                cartItems={cartItems}
            />
        </>
    );
}

CartModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleHideModal: PropTypes.func.isRequired,
    handleEmptyCart: PropTypes.func.isRequired,
    handleRemoveItem: PropTypes.func.isRequired,
    handleUpdateItem: PropTypes.func.isRequired,
    cartItems: PropTypes.array.isRequired,
};

export default CartModal;
