import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function CartTableRow({ item, handleUpdateItem, handleRemoveItem }) {
    const { t } = useTranslation();
    const [qty, setQty] = useState(item.qty ?? 1);

    const decrementItem = (itemId, oldQty) => {
        setQty((prevState) => prevState - 1);
        handleUpdateItem(itemId, oldQty - 1);
    };
    const incrementItem = (itemId, oldQty) => {
        setQty((prevState) => prevState + 1);
        handleUpdateItem(itemId, oldQty + 1);
    };

    const handleChange = (itemId, target) => {
        const intQty = parseInt(target.value);
        setQty(intQty);
        handleUpdateItem(itemId, intQty);
    };

    return (
        <tr>
            <td>
                <img
                    src={process.env.REACT_APP_ASSETS_URL + 'products/' + item.product.image}
                    alt=""
                    style={{ width: '35px' }}
                />
            </td>
            <td>{item.product.name}</td>
            <td>
                <div className="form-group">
                    <div className="input-group input-group-sm flex-nowrap">
                        <div className="input-group-prepend">
                            <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                aria-label={t('Decrement')}
                                onClick={() => decrementItem(item.product.id, qty)}
                            >
                                -
                            </button>
                        </div>
                        <input
                            type="number"
                            name="qty"
                            value={qty}
                            className="from-control text-center"
                            min="1"
                            step="1"
                            style={{ width: '60px' }}
                            onChange={(e) => handleChange(item.product.id, e.target)}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text text-uppercase">{t('PCS')}</span>
                            <button
                                type="button"
                                className="btn btn-success btn-sm"
                                style={{ zIndex: 0 }}
                                aria-label={t('Increment')}
                                onClick={() => incrementItem(item.product.id, qty)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => handleRemoveItem(item.product.id)}
                    className="text-danger cursor-pointer"
                />
            </td>
        </tr>
    );
}

export default CartTableRow;
