const index = () => {
    return fetch(process.env.REACT_APP_API_URL + 'categories.json').then(res => res.json())
}

const view = (id) => {
    return fetch(process.env.REACT_APP_API_URL + 'categories/' + id + '.json').then(res => {
        if (200 <= res.status <= 302) {
            return res.json();
        }

        return res;
    })
}

const CategoryType = {
    id: 1,
    name: 'Wago',
    slug: 'placeholder',
    image: 'wago-logo.png',
    child_categories: [],
    parent_category: {},
}
CategoryType.parent_category = CategoryType;

export {index, view, CategoryType}
