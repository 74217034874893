import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function useSearch(history) {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [internalSearchTerm, setInternalSearchTerm] = useState('');
    const [searchFormSubmitted, setSearchFormSubmitted] = useState(false);

    const handleSearchChange = (event) => {
        const target = event.target;
        setInternalSearchTerm(target.value);
    };
    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (internalSearchTerm.length <= 0) {
            setSearchTerm('');
            setSearchFormSubmitted(false);
            return false;
        }
        setSearchTerm(internalSearchTerm);
        setSearchFormSubmitted(true);
        history.push('/search/' + internalSearchTerm);
    };

    const searchForm = (
        <form className="form-inline my-2 my-lg-0" onSubmit={handleSearchSubmit}>
            <input
                name="search"
                type="search"
                className="form-control mr-sm-2"
                aria-label="Søk"
                placeholder="Søk"
                value={internalSearchTerm}
                onChange={handleSearchChange}
            />
            <button className="btn btn-outline-darkblue my-2 my-sm-0">{t('Search')}</button>
        </form>
    );

    return { searchTerm, searchForm, searchFormSubmitted };
}

export default useSearch;
