function useCartStorage() {
    const PREFIX = 'wago.';

    const getStorageItems = () => {
        const keys = Object.keys(localStorage);
        const items = [];
        keys.forEach((key) => {
            if (key.startsWith(PREFIX + 'items')) {
                items.push(JSON.parse(localStorage.getItem(key)));
            }
        });

        return items;
    };

    const addItemToStorage = (item) => {
        localStorage.setItem(PREFIX + 'items.' + item.product.id + '.', JSON.stringify(item));
    };

    const updateItemQtyStorage = (itemId, newQty) => {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
            if (key.startsWith(PREFIX + 'items.' + itemId + '.')) {
                const itemStorage = localStorage.getItem(key);
                const itemObject = JSON.parse(itemStorage);
                itemObject.qty = newQty;

                localStorage.setItem(key, JSON.stringify(itemObject));
            }
        });
    };

    const removeItemFromStorage = (itemId) => {
        localStorage.removeItem(PREFIX + 'items.' + itemId + '.');
    };

    const clearStorage = () => {
        const keys = Object.keys(localStorage);
        keys.forEach((key) => {
            if (key.startsWith(PREFIX)) {
                localStorage.removeItem(key);
            }
        });
    };

    return { getStorageItems, addItemToStorage, updateItemQtyStorage, removeItemFromStorage, clearStorage };
}

export default useCartStorage;
