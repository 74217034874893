import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CartTableRow from './CartTableRow';

function CartTable({ cartItems, handleRemoveItem, handleUpdateItem }) {
    const { t } = useTranslation();

    if (cartItems.length <= 0) {
        return <h5 className="text-danger">{t('EmptyCartMessage')}</h5>;
    }

    const cartItemsRows = cartItems.map((item) => (
        <CartTableRow
            key={item.product.id}
            item={item}
            handleUpdateItem={handleUpdateItem}
            handleRemoveItem={handleRemoveItem}
        />
    ));

    return (
        <table id="cartTable" className="table table-hover table-sm table-responsive-md">
            <tbody>{cartItemsRows}</tbody>
        </table>
    );
}

CartTable.propTypes = {
    cartItems: PropTypes.array.isRequired,
    handleRemoveItem: PropTypes.func.isRequired,
    handleUpdateItem: PropTypes.func.isRequired,
};

export default CartTable;
