import PropTypes from 'prop-types';
import Product from './Product';
import { useTranslation } from 'react-i18next';

function Products({ products, cartActions }) {
    const { t } = useTranslation();

    if (products.length <= 0) {
        return <h5 className="text-danger text-center mt-3">{t('NoProductsFound')}.</h5>;
    }

    const productsContent = products.map((product) => (
        <Product product={product} key={product.id} cartActions={cartActions} />
    ));

    return <div className="list-group list-group-flush">{productsContent}</div>;
}

Products.propTypes = {
    products: PropTypes.array.isRequired,
    cartActions: PropTypes.elementType.isRequired,
};

export default Products;
