import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

function CartActions({ addItemToCart, externalQty, product }) {
    const { t } = useTranslation();
    const [qty, setQty] = useState(externalQty ?? 1);

    const dropdownItems = () => {
        let items = [];
        for (let i = 1; i <= 5; i++) {
            items.push(
                <Dropdown.Item as="button" key={i} aria-label={'Sett antall til ' + i} onClick={() => setQty(i)}>
                    {i} {t('PCS')}
                </Dropdown.Item>,
            );
        }

        return items;
    };

    const handleChange = (event) => {
        const target = event.target;
        if (target.value <= 0) {
            return setQty(1);
        }
        setQty(parseInt(target.value));
    };

    const decrementCount = () => {
        setQty((prevState) => (prevState <= 1 ? prevState : prevState - 1));
    };

    const incrementCount = () => {
        setQty((prevState) => prevState + 1);
    };

    const handleAddToCart = (item, qty) => addItemToCart(item, qty);

    // Requried to prevent dropdown button from having a div container, which increases the height of the button
    const dropdownContainer = (dropdown) => dropdown.children;

    return (
        <div className="form-group ">
            <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <button className="btn btn-danger btn" type="button" onClick={decrementCount}>
                        -
                    </button>
                </div>
                <input
                    type="number"
                    name="qty"
                    className="form-control text-center"
                    value={qty ?? 1}
                    min="1"
                    step="1"
                    style={{ width: '60px' }}
                    onChange={handleChange}
                />
                <div className="input-group-append">
                    <span className="input-group-text text-uppercase">{t('PCS')}</span>
                    <button type="button" className="btn btn-success" style={{ zIndex: 0 }} onClick={incrementCount}>
                        +
                    </button>
                    <Dropdown as={dropdownContainer}>
                        <Dropdown.Toggle split variant="outline-success" className="rounded-0 border-right-0" />
                        <Dropdown.Menu>{dropdownItems()}</Dropdown.Menu>
                    </Dropdown>
                    <button
                        type="button"
                        className="btn btn-outline-success"
                        style={{ zIndex: 0 }}
                        onClick={() => handleAddToCart(product, qty)}
                    >
                        <FontAwesomeIcon icon={faShoppingCart} />
                    </button>
                </div>
            </div>
        </div>
    );
}

CartActions.propTypes = {
    addItemToCart: PropTypes.func.isRequired,
    product: PropTypes.object,
    externalQty: PropTypes.number,
};

export default CartActions;
