import PropTypes from 'prop-types';

function Placeholder({ count }) {
    count = count || 5;

    let placeholderItems = [];
    for (let i = 0; i < count; i++) {
        placeholderItems.push(
            <div className="list-group-item" key={i}>
                <div className="media align-items-center">
                    <div className="placeholder-item-image loads" />
                    <div className="placeholder-item-text">
                        <div className="loads placeholder-item-text">
                            <div className="placeholder-item-text-line" />
                        </div>
                        <div className="loads placeholder-item-text">
                            <div className="placeholder-item-text-line" />
                        </div>
                    </div>
                </div>
            </div>,
        );
    }

    return <div className="list-group list-group-flush">{placeholderItems}</div>;
}

Placeholder.propTypes = {
    count: PropTypes.number,
};

export default Placeholder;
