import { useEffect, useState } from 'react';
import { index, ProductType } from '../services/Api/ProductsApi';
import Products from '../components/Products';
import Placeholder from '../components/Placeholder';
import usePaginator from '../hooks/usePaginator';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProductsPage({ cartActions }) {
    const { t } = useTranslation();
    const queryPage = new URLSearchParams(useLocation().search).get('page') ?? null;

    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([ProductType]);
    const [paginatorParams, setPaginatorParams] = useState({});

    const { paginatorContent } = usePaginator(paginatorParams);

    useEffect(() => {
        setIsLoaded(false);
        index(queryPage).then((result) => {
            setError(result.error || null);
            setProducts(result.products);
            if (result.hasOwnProperty('paginator') && result.paginator.hasOwnProperty('Products')) {
                setPaginatorParams(result.paginator.Products);
            }
            setIsLoaded(true);
        });
    }, [queryPage]);

    if (error) {
        return (
            <div>
                {t('Error')}: {error.message}
            </div>
        );
    }
    if (!isLoaded) {
        return <Placeholder />;
    }

    return (
        <>
            <Products products={products} cartActions={cartActions} />
            {paginatorContent}
        </>
    );
}

export default ProductsPage;
